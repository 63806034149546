<template>
	<div class="bigBox">
		<h2>设备管理</h2>
		<div class="">
			<div class="iptsBox margin-top20">
				<el-form ref="form" :model="form" label-width="80px">
					<div class="flex-sb row-me row-center space-between">
						<div class="row-me">
							<div class="margin-right10">
								<el-select v-model="form.state" placeholder="设备状态" class="electIpt">
									<el-option label="正常" value="1"></el-option>
									<el-option label="异常" value="2"></el-option>
									<el-option label="离线" value="3"></el-option>
								</el-select>
							</div>
							<div class="width360 posRelative margin-right10 nameBox">
								<img src="../../assets/img/icon_search.png" alt=""
									class="height16 width16 posAbsolute icon_search" />
								<el-input placeholder="IEME编码/设备编码/设备名称" style="width: 100%;" clearable
									v-model="form.keyword"></el-input>
							</div>
							<div class="margin-right10">
								<el-button type="primary" class="searchs" @click="clickSearch">
									<img src="../../assets/img/icon_sousuo_b.png" alt="" />
									搜索
								</el-button>
							</div>
							<el-button class="agains" @click="clickReset">重置
							</el-button>
						</div>
						<div class="row-me row-center">
							<div class="row-me row-center btn1" @click="consumptionClick">
								<img src="../../assets/img/icon_yzxhtj.png">&nbsp;
								<span class="youzhi">油脂消耗统计</span>
							</div>
							<div class="row-me row-center btn2" @click="importClick">
								<img src="../../assets/img/icon_pldc.png">&nbsp;
								<span class="youzhi">批量导入</span>
							</div>
							<div class="row-me row-center btn3" @click="addEquipment">
								<img src="../../assets/img/icon_xzsb.png">&nbsp;
								<span class="youzhi">新增设备</span>
							</div>
						</div>

					</div>
				</el-form>
			</div>
			<div class="tabsBox  margin-top20">
				<el-table :data="deviceTabList" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<!-- <el-table-column type="index" label="序号" width="55" align="center">
					</el-table-column> -->
					<el-table-column prop="ieme_encoded" label="IEMI编码" align="center">
					</el-table-column>
					<el-table-column prop="device_encoded" label="设备编码" align="center">
					</el-table-column>
					<el-table-column prop="run_time" label="运行时间" align="center">
					</el-table-column>
					<el-table-column prop="state" label="设备状态" align="center">
						<template #default="scope">
							<div class="" v-if="scope.row.state == 1" style="color: #33C77C;">正常</div>
							<div class="" v-if="scope.row.state == 2" style="color: #EB4B4D;">异常</div>
							<div class="" v-if="scope.row.state == 3" style="color: #9D9FA3;">离线</div>
						</template>
					</el-table-column>
					<el-table-column prop="signal_state" label="信号状态" align="center">
					</el-table-column>
					<el-table-column prop="name" label="设备名称" align="center">
					</el-table-column>
					<el-table-column prop="device_cate" label="设备应用" align="center">
					</el-table-column>
					<el-table-column prop="label" label="设备标签" align="center">
					</el-table-column>
					<el-table-column prop="areainfo" label="所在地区" align="center">
					</el-table-column>
					<el-table-column prop="use_time" label="始用时间" align="center">
					</el-table-column>
					<el-table-column prop="alarm_text" label="报警原因" align="center">
					</el-table-column>
					<el-table-column prop="alarm_time" label="报警时间" align="center">
					</el-table-column>
					<el-table-column label="操作" align="left">
						<template #default="scope">
							<div class="actionBox">
								<el-button type="text" size="small" class="detailsTxt"
									@click="editClick(scope.row)">详情</el-button>
								<el-button type="text" size="small" @click="deleteClick(scope.row)">删除</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="fenye" v-if="total>0">
				<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
					:current-page.sync="pageNo" :total="total">
				</el-pagination>

				<!-- <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo"
				               layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination> -->
			</div>
		</div>
		<el-dialog title="导入" v-model="importShow" class="create3" width="60%">
			<div class="pptBoxDiv">
				<div class="pptBox">
					<div class="row-me row-center">
						<div class="template">
							模板下载：
						</div>
						<div class="reminder">
							<div class="pptBoxR row-me row-center">
								<!-- <img src="../../assets/img/excel.png"> -->
								设备导入模板[Excel]
								<div class="flex1" />
								<div class="download" @click="download()">下载</div>
							</div>
							<!-- <img src="../../assets/img/tanhao.png"> -->
							<span>请先下载我们提供给您的模板，修改填入数据后再导入文件。</span>
						</div>
					</div>
					<div class="importFile row-me row-center">
						<div class="templates">
							导入文件：
						</div>

						<el-upload class="upload-demo" action="" :on-preview="handlePreview" :on-remove="handleRemove"
							:before-remove="beforeRemove" multiple :limit="1" :on-exceed="handleExceed"
							:auto-upload="false" :on-change="fileChange" accept=".xlsx,.xls">
							<el-button size="small" type="primary" class="upload">点击上传</el-button>
							<!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
						</el-upload>
					</div>
				</div>
				<div class="row-me row-center">
					<el-button type="blue" class="save" @click="submitFormInc()">保存</el-button>
					<el-button type="grey" class="callOff" @click="callOffInc()">取消</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					keyword: "",
					state: "",
					region2: "",
				},
				deviceTabList: [],
				// 分页开始
				pageNo: 1,
				total: 0,
				// 分页结束
				importShow: false,
				file: null,
			}
		},
		mounted() {
			this.getDeviceTabList()
		},
		methods: {
			clickSearch() {
				this.getDeviceTabList()
			},
			clickReset() {
				this.form.keyword = '';
				this.form.state = '';
				this.getDeviceTabList()
			},
			// 设备管理表格
			getDeviceTabList() {

				this.$post("index/deviceListInfo", {
					keyword: this.form.keyword,
					state: this.form.state,
					page: this.pageNo,
				}).then((res) => {
					if (res) {
						this.total = res.data.allListNumber
						this.deviceTabList = res.data.listInfo
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.getDeviceTabList()
			},
			// 设备详情
			editClick(row) {
				this.$router.push({
					path: './goDeviceDetalis',
					query: {
						type: 1,
						id: row.id
					}
				})
			},
			deleteClick(row) {
				console.log(row, 'rowrow')
				this.$confirm("解绑该设备，有关该设备所有数据均清空!", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$post('/index/deviceInfoDel', {
						id: row.id
					}).then((res) => {
						if (res) {
							this.$message.success('删除成功')
							this.getDeviceTabList()
						}
					}).catch((error) => {
						console.log(error)
					})
				}).catch(() => {});
			},
			// 点击油脂消耗统计
			consumptionClick() {
				this.$router.push("/consumption");
			},
			// 点击新增设备
			addEquipment() {
				this.$router.push({
					path: './goDeviceDetalis',
					query: {
						type: 0,
						// order_no: item.order_no
					}
				})
			},
			// 点击批量导入
			importClick() {
				this.importShow = true;
			},
			// 监听上传文件
			getName(data) {
				this.file = data;
			},
			callOffInc() {
				this.file = null;
				this.importShow = false
			},
			// 保存文件
			submitFormInc() {
				let formData = new FormData();
				formData.append("file", this.file);
				if (this.file == null) {
					this.$message.error('请先上传文件')
					return false
				}
				// this.$post('/index/deviceInfoImport', formData).then((res) => {
				this.$uploadFile('/index/deviceInfoImport', formData).then((res) => {
					if (res) {
						this.file = null;
						this.importShow = false;
						this.$message.success(res.message);
						this.getDeviceTabList()
					}
				}).catch((error) => {
					console.log(error);
				})

				// const formData = new FormData()
				// formData.append('file', this.file)
				// if (this.file == null) {
				// 	this.$message.error('请先上传文件')
				// 	return false
				// }
				// console.log(sessionStorage.getItem('token'), 'token')
				// axios.defaults.baseURL = process.env.VUE_APP_BASE_API
				// axios.post('/index/deviceInfoImport', formData, {
				// 	'Content-type': 'multipart/form-data',
				// 	headers: {
				// 		'indexToken': sessionStorage.getItem('token')
				// 	}
				// })
				// this.file = null
				// this.getDeviceTabList()
				// this.importShow = false
			},
			fileChange(file) {
				this.file = file.raw
				console.log(this.file, 'this.file')
			},
			download() {
				// const url = '../../assets/file/DILC设备信息导入模板.xlsx';
				const url = 'https://image.zrms.com.cn/1682558108635DILC设备信息导入模板.xlsx';
				window.open(url)

				// const url = 'https://system.lyyouyue.com' + '/admin/importQuestionTemplate?type=1'
				// window.open(url)
			},
			handleRemove(file, fileList) {
				console.log(file, fileList, '219')
			},
			handlePreview(file) {
				console.log(file, '222')
			},
			handleExceed() {
				this.$message.warning(`当前限制选择 1 个文件，请先删除再上传`)
			},
			beforeRemove(file) {
				return this.$confirm(`确定移除 ${file.name}？`)
			}
		},

	}
</script>

<style lang="scss" scoped="scoped">
	/deep/.el-button {
		margin-left: 0 !important;
		padding: 0 !important;

		span {
			font-size: 18px;
		}
	}

	/deep/.el-dialog__header {
		background: none !important;
		border-bottom: 1px solid #f5f6f8;

		.el-dialog__title {
			color: #000;
		}

		.el-dialog__close {
			color: #000;
		}
	}

	.create3 {
		.el-button {
			width: 100px;
			height: 40px;
		}

		.save {
			width: 100px;
			height: 40px;
			line-height: 40px;
			background: #3D6EE2;
			border-radius: 4px;
			font-size: 18px;
			color: #FFFFFF;
			margin-right: 20px;
		}

		.callOff {
			width: 98px;
			height: 38px;
			line-height: 38px;
			border-radius: 4px;
			font-size: 18px;
			color: #333;
			margin-right: 20px;
			border: 1px solid #D6D9E2;
		}

		.pptBoxDiv {
			width: 750px;
			margin: 0 auto;
		}

		a {
			text-decoration: none;
			color: #215aa0;
		}

		.pptBox {
			font-size: 20px;
		}

		.importFile {
			margin-top: 30px;
			margin-bottom: 50px;

			.templates {
				height: 40px;
			}

			.el-icon-close:before {
				font-size: 8px !important;
				font-size: 0.2rem !important;
				line-height: 0.2rem !important;
			}

			.upload {
				background: none !important;
				color: #000;
				margin-left: 0 !important;
			}
		}

		.template {
			height: 100px;
			line-height: 75px;
		}

		.reminder {
			span {
				margin-left: 9px;
				font-size: 14px;
				color: rgb(169, 182, 196);
			}
		}

		.pptBoxR {
			width: 600px;
			line-height: 80px;
			height: 80px;
			background: rgb(245, 246, 248);
			border-radius: 4px;
			padding: 0 20px;
			margin-bottom: 5px;

			img {
				display: inline-block;
				vertical-align: middle;
				width: 40px;
				margin-right: 20px;
			}

			.download {
				width: 90px;
				height: 40px;
				line-height: 40px;
				border: 1px solid rgb(229, 233, 237);
				border-radius: 4px;
				font-size: 18px;
				color: rgb(33, 90, 160);
				text-align: center;
				cursor: pointer;
			}
		}
	}

	.actionBox {
		/deep/.el-button {
			span {
				font-size: 16px !important;
			}
		}
	}

	.nameBox {
		/deep/.el-input__inner {
			padding-left: 30px;
		}

		.icon_search {
			top: 13px;
			left: 13px;
			z-index: 9;
		}
	}

	.detailsTxt {
		padding-right: 20px !important;
	}

	.searchs {
		margin-left: 30px;
		font-size: 16px;

		img {
			width: 16px;
			height: 16px;
			margin-right: 10px;
		}
	}

	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	.youzhi {
		font-size: 18px;
		float: right;
	}

	.agains {
		margin-left: 10px;
		width: 100px;
		height: 40px;
	}


	.btn1 {
		border: 1px solid #D4D7E0;
		border-radius: 4px;
		padding: 6px 8px;
		font-size: 18px;
		color: #252630;
		cursor: pointer;

		img {
			width: 26px;
			height: 26px;
		}
	}

	.btn2 {
		border: 1px solid #D4D7E0;
		border-radius: 4px;
		margin-left: 10px;
		margin-right: 10px;
		padding: 6px 8px;
		font-size: 18px;
		color: #252630;
		cursor: pointer;

		img {
			width: 26px;
			height: 26px;
		}
	}

	.btn3 {
		border: 1px solid #D4D7E0;
		border-radius: 4px;
		padding: 6px 8px;
		font-size: 18px;
		color: #252630;
		cursor: pointer;

		img {
			width: 26px;
			height: 26px;
		}
	}

	.searchs {
		margin-left: 10px;
		font-size: 16px;
	}

	.electIpt {
		width: 200px;
		height: 40px;
		margin-left: 10px;
	}

	.fenye {
		float: right;
		margin-top: 40px;
	}
</style>